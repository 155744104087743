<template>
  <div class="pages">
    <div class="record-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>账户密码</span>
        </div>
      </div>
    </div>
    <div class="input-info">
      <div class="input-text">
        <div class="title">
          <span>手机号</span>
        </div>
        <div class="content">
          <span>{{ phoneNumberText }}</span>
        </div>
      </div>
      <div class="input-text">
        <div class="title">
          <span>验证码</span>
        </div>
        <div class="content">
          <div class="fill">
            <input type="text" v-model="form.sms_code" placeholder="请输入验证码" style="width: 140px">
            <span class="code" :class="{send:sms_send}" @click="handleClickSendSms">{{ sendSmsText }}</span>
          </div>
        </div>
      </div>
      <div class="input-text">
        <div class="title">
          <span>新密码</span>
        </div>
        <div class="content">
          <div class="fill">
            <input type="text" v-model="form.passwd" placeholder="设置新密码">
          </div>
        </div>
      </div>
      <div class="input-text">
        <div class="title">
          <span>确认新密码</span>
        </div>
        <div class="content">
          <div class="fill">
            <input type="text" v-model="form.confirm_passwd" placeholder="确认新密码">
          </div>
        </div>
      </div>
    </div>
    <div class="btn-info">
      <div class="btn" :class="{disable:loading}" @click="handleSubmitClick">
        <span>确认修改密码</span>
      </div>
    </div>
  </div>
</template>

<script>
let sms_countdown_interval = null
export default {
  name: 'ChangePassword',
  data() {
    return {
      memberInfo: {},
      sms_send: false,
      loading: false,
      sms_countdown: 0,
      form: {
        sms_code:'',
        passwd: '',
        confirm_passwd: '',
      }
    }
  },
  created() {
    this.memberInfo = JSON.parse(localStorage.getItem('MEMBER'))
  },
  methods: {
    async handleSubmitClick() {
      if (this.loading) {
        return
      }
      try {
        this.loading = true
        if (this.form.passwd !== this.form.confirm_passwd) {
          this.$message({
            message: '两次密码输入不一致',
            type: 'error'
          })
          return
        }

        await this.http({
          url: '/api/account/changePassword',
          method: 'POST',
          data:{
            phone_number: this.memberInfo.phone_number,
            sms_code: this.form.sms_code,
            passwd: this.form.passwd,
          }
        })

        this.$message({
          message: '操作成功',
          type: 'success'
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async handleClickSendSms() {
      try {
        if (this.sms_send) {
          return
        }

        let res = await this.http({
          url: '/api/account/sendSmsCode',
          method: 'post',
          data: {
            phone_number: this.memberInfo.phone_number
          }
        })

        this.startSmsCountDown()

        this.$message({
          message: res.msg,
          type: 'success'
        })

        console.log(res)

      } catch (e) {
        console.log(e)
      }
    },
    startSmsCountDown() {
      this.sms_send = true
      this.sms_countdown = 60
      localStorage.setItem('sms_countdown', ((new Date()).getTime() + this.sms_countdown * 1000).toString())
      setInterval(this.handleSmsCountDown, 1000)
    },
    handleSmsCountDown() {
      if (this.sms_countdown <= 1) {
        this.sms_send = false
        clearInterval(sms_countdown_interval)
        sms_countdown_interval = null
        localStorage.removeItem('sms_countdown')
        return
      }
      this.sms_countdown -= 1
    },
  },
  computed: {
    phoneNumberText() {
      if (this.memberInfo.phone_number === undefined) {
        return ''
      }
      return `${this.memberInfo.phone_number.slice(0, 3)}****${this.memberInfo.phone_number.slice(7)}`
    },
    sendSmsText() {
      if (!this.sms_send) {
        return '发送验证码'
      }
      return `已发送(${this.sms_countdown})`
    }
  }
}
</script>

<style scoped lang="scss">
@import "ChangePassword.scss";
</style>
